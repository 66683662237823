var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.container,attrs:{"id":"check"}},[_c('div',{class:_vm.$style.info},[_c('h2',{class:_vm.$style.title},[_vm._v("\n      Проверить"),_c('br'),_vm._v("\n      заявку\n    ")]),_vm._v(" "),_c('p',{class:_vm.$style.description},[_vm._v("\n      Для проверки статуса заявки введите адрес электронной почты,\n      который вы указали при подаче проекта на конкурс\n    ")])]),_vm._v(" "),_c('form',{class:_vm.$style.form,on:{"submit":function($event){$event.preventDefault();return _vm.checkEmail.apply(null, arguments)}}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.step),expression:"!step"},{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:_vm.$style.formInput,attrs:{"type":"email","placeholder":"Введите электронный адрес"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_vm._v(" "),(_vm.step)?_c('div',{class:_vm.$style.step},[_c('span',{class:_vm.$style.stepTitle},[_vm._v("\n        Статус вашей заявки:\n      ")]),_vm._v(" "),_c('span',{class:[
          _vm.$style.stepValue,
          { [_vm.$style.stepNotFound]: _vm.step === 'notfound' }
        ],domProps:{"innerHTML":_vm._s(
          _vm.step === 'notfound'
            ? 'конкурсная работа с такими данными не найдена'
            : 'заявка рассмотрена, результаты будут опубликованы 25&nbsp;мая&nbsp;2024&nbsp;г.'
        )}})]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],class:_vm.$style.error},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]),_vm._v(" "),_c('div',{class:_vm.$style.formButtons},[_c('index-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.step),expression:"!step"}],attrs:{"text":"проверить","type":"submit","disabled":_vm.loading}}),_vm._v(" "),_c('index-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step),expression:"step"}],attrs:{"text":"проверить еще раз","type":"button","variant":"outline"},on:{"click":function($event){_vm.step = null}}}),_vm._v(" "),_c('index-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 'notfound'),expression:"step === 'notfound'"}],class:_vm.$style.btn,attrs:{"text":"подать заявку","to":"/auth/registration"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }