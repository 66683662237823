// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9nNc7U2D{display:flex;flex-direction:column}@media(max-width:1324.98px){._9nNc7U2D{padding:0 var(--spacing-lg)}}@media(max-width:667.98px){._9nNc7U2D{padding:0 var(--spacing-md)}}.bt3\\+kUzl{display:flex;gap:1rem;justify-content:space-between}@media(max-width:947.98px){.bt3\\+kUzl{flex-direction:column;gap:0}.bt3\\+kUzl br{display:none}}.EYXj7Q5o{color:var(--color-black);font-size:max(1.13rem,min(2vw,1.5rem));font-weight:400}.AehYLWjn{color:var(--color-primary);font-size:1.13rem}.kTjIe-2-{grid-gap:1rem;display:grid;gap:1rem;grid-template-columns:repeat(3,1fr)}._0O\\+Igedq{text-align:center}.SR6Ckokp{align-items:center;aspect-ratio:1/1;display:flex}.SR6Ckokp img{width:100%}.tyBOLEhy{color:var(--color-black);font-size:max(1.13rem,min(2vw,1.5rem));font-weight:400}.P-nZiuHK{font-size:1.13rem}@media(max-width:667.98px){.kTjIe-2-{display:flex;flex-direction:column;gap:0}}.ERKaOStK{align-self:center;margin-top:4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_9nNc7U2D",
	"header": "bt3+kUzl",
	"title": "EYXj7Q5o",
	"description": "AehYLWjn",
	"categories": "kTjIe-2-",
	"categoriesItem": "_0O+Igedq",
	"categoriesImage": "SR6Ckokp",
	"categoriesTitle": "tyBOLEhy",
	"categoriesDescription": "P-nZiuHK",
	"btn": "ERKaOStK"
};
module.exports = ___CSS_LOADER_EXPORT___;
