
import Vue from "vue"
import { mapState, mapActions } from "vuex"

export default Vue.extend({
  computed: {
    ...mapState("app", { faq: "faq" }),
  },

  mounted () {
    this.fetchFaq()
  },

  methods: {
    ...mapActions("app", { fetchFaq: "FETCH_FAQ" }),
  },
})
