
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import { Winner } from "@/types/api/winner"

export default Vue.extend({
  data: () => ({
    isOpen: false,
    selected: null as Winner | null,
    groupSize: 3,
    currentGroup: 0,
    windowWidth: null as Number | null,
    categories: {
      first: "1 категория – 8-10 лет",
      second: "2 категория – 11-14 лет",
      third: "3 категория – 15-18 лет",
    },
  }),

  computed: {
    ...mapState("user", { winners: "winners" }),

    groups () {
      const result: Winner[] = []
      const size = this.groupSize
      for (let i = 0; i < Math.ceil(this.winners.length / size); i++) result[i] = this.winners.slice((i * size), (i * size) + size)
      return result
    },
  },

  watch: {
    windowWidth (val) {
      if (val <= 668) this.groupSize = 1
      else if (val <= 948) this.groupSize = 2
      else this.groupSize = 3
      this.currentGroup = 0
    },
  },

  beforeMount () {
    this.onResize()
  },
  mounted () {
    this.fetchWinners()
    window.addEventListener("resize", this.onResize)
    window.addEventListener("keydown", this.onKeydown)
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
    window.removeEventListener("keydown", this.onKeydown)
  },

  methods: {
    ...mapActions("user", { fetchWinners: "fetchWinners" }),

    formatName (raw: string) {
      const splitted = raw.split(" ")
      if (splitted.length === 1) return raw
      return `${splitted[0]} ${splitted[1].charAt(0)}.`
    },

    onSelect (item: Winner) {
      this.selected = item
      this.isOpen = true
    },

    close () {
      this.selected = null
      this.isOpen = false
    },

    onResize () {
      this.windowWidth = window.innerWidth
    },

    onKeydown (e: KeyboardEvent) {
      if (this.isOpen && e.code === "Escape") this.close()
    },

    nextGroup () {
      if (this.currentGroup < this.groups.length - 1) this.currentGroup++
    },

    prevGroup () {
      if (this.currentGroup > 0) this.currentGroup--
    },
  },
})
