var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.container,attrs:{"id":"results2023"}},[_c('h2',{class:_vm.$style.title},[_vm._v("\n    Победители и финалисты 2023\n  ")]),_vm._v(" "),_c('video',{class:_vm.$style.video,attrs:{"controls":""}},[_c('source',{attrs:{"src":"/video/video.mp4","type":"video/mp4"}}),_vm._v("\n    Видео не поддерживается браузером\n  ")]),_vm._v(" "),(_vm.winners.length && _vm.windowWidth)?_c('div',{class:_vm.$style.slider},[_c('div',{class:_vm.$style.sliderContent,style:({ transform: `translateX(-${_vm.currentGroup * 100}%)` })},_vm._l((_vm.groups),function(g,i){return _c('div',{key:i,class:_vm.$style.group},_vm._l((g),function(w){return _c('button',{key:w.id,class:_vm.$style.item,on:{"click":function($event){$event.preventDefault();return _vm.onSelect(w)}}},[_c('div',{class:_vm.$style.picture},[(w.picture)?_c('img',{attrs:{"src":w.picture.url,"alt":"Работа участника","loading":"lazy"}}):_vm._e()]),_vm._v(" "),_c('h4',{class:_vm.$style.name,domProps:{"innerHTML":_vm._s(_vm.formatName(w.name))}}),_vm._v(" "),(w.age)?_c('span',{class:_vm.$style.info,domProps:{"innerHTML":_vm._s(_vm.categories[w.age] ?? '')}}):_vm._e(),_vm._v(" "),_c('span',{class:_vm.$style.info,domProps:{"innerHTML":_vm._s(w.region)}})])}),0)}),0),_vm._v(" "),_c('div',{class:_vm.$style.switcher},[_c('button',{class:[
          _vm.$style.switcherPrev,
          { [_vm.$style.switcherDisabled]: _vm.currentGroup <= 0 }
        ],on:{"click":function($event){$event.preventDefault();return _vm.prevGroup.apply(null, arguments)}}},[_c('img',{class:_vm.$style.switcherIcon,attrs:{"src":"/landing/arrow-right.svg","alt":"Стрелка","loading":"lazy"}})]),_vm._v(" "),_c('div',{class:_vm.$style.switcherDots},_vm._l((_vm.groups),function(g,i){return _c('div',{key:i,class:[
            _vm.$style.dot,
            { [_vm.$style.dotActive]: i === _vm.currentGroup }
          ]})}),0),_vm._v(" "),_c('button',{class:[
          _vm.$style.switcherNext,
          { [_vm.$style.switcherDisabled]: _vm.currentGroup >= _vm.groups.length - 1 }
        ],on:{"click":function($event){$event.preventDefault();return _vm.nextGroup.apply(null, arguments)}}},[_c('img',{class:_vm.$style.switcherIcon,attrs:{"src":"/landing/arrow-right.svg","alt":"Стрелка","loading":"lazy"}})])])]):_vm._e(),_vm._v(" "),_c('button',{class:[
      _vm.$style.modal,
      { [_vm.$style.modalOpen]: _vm.isOpen }
    ],on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[(_vm.selected)?_c('div',{class:_vm.$style.modalContent},[_c('div',{class:_vm.$style.modalInfo},[_c('h4',{class:_vm.$style.name,domProps:{"innerHTML":_vm._s(_vm.formatName(_vm.selected.name))}}),_vm._v(" "),_c('span',{class:_vm.$style.info,domProps:{"innerHTML":_vm._s(_vm.selected.region)}}),_vm._v(" "),(_vm.selected.age)?_c('span',{class:_vm.$style.info,domProps:{"innerHTML":_vm._s(_vm.categories[_vm.selected.age] ?? '')}}):_vm._e()]),_vm._v(" "),_c('div',{class:_vm.$style.modalImage},[_c('img',{attrs:{"src":_vm.selected.picture.url,"alt":"Работа участника","loading":"lazy"}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }