// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4n\\+TOZ9H{display:flex}@media(max-width:1324.98px){._4n\\+TOZ9H{padding:0 var(--spacing-lg)}}@media(max-width:1023.98px){._4n\\+TOZ9H{flex-direction:column}}@media(max-width:667.98px){._4n\\+TOZ9H{padding:0 var(--spacing-md)}}.xwTfHKMG{align-items:flex-start;display:flex;gap:var(--spacing-md);margin-bottom:var(--spacing-lg)}.YLZAE2Ht{width:2.5rem}@media(max-width:667.98px){.YLZAE2Ht{width:1.8rem}}.RtX17yNe{color:var(--color-black);font-size:1.13rem}.c07xPtBm,.xpSJ0fEs{color:var(--color-primary)}.c07xPtBm{font-size:max(2.19rem,min(4vw,3.75rem));line-height:1.1;padding:0 6rem 0 8rem;text-transform:uppercase}@media(max-width:1023.98px){.c07xPtBm{padding:0}.c07xPtBm br{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_4n+TOZ9H",
	"item": "xwTfHKMG",
	"itemIcon": "YLZAE2Ht",
	"itemText": "RtX17yNe",
	"goodluck": "c07xPtBm",
	"itemLink": "xpSJ0fEs"
};
module.exports = ___CSS_LOADER_EXPORT___;
