// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uxip47-U{display:flex;flex-direction:column;flex-grow:1;gap:6rem;padding:4rem 0}@media(max-width:667.98px){.Uxip47-U{padding:2.5rem 0}}.e17KewmM{display:none}@media(max-width:1023.98px){.e17KewmM{display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"content": "Uxip47-U",
	"mSponsors": "e17KewmM"
};
module.exports = ___CSS_LOADER_EXPORT___;
