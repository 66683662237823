// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7AkAketf{grid-gap:4rem;display:grid;gap:4rem;grid-template-columns:repeat(2,1fr)}@media(max-width:1324.98px){._7AkAketf{padding:0 var(--spacing-lg)}}@media(max-width:1023.98px){._7AkAketf{display:flex;flex-direction:column;gap:2rem}}@media(max-width:667.98px){._7AkAketf{padding:0 var(--spacing-md)}}.dYWilShA{display:flex;flex-direction:column;gap:var(--spacing-lg)}.yIhlck5n{color:var(--color-primary);font-size:max(2.19rem,min(4vw,3.75rem));line-height:1.1;text-transform:uppercase}@media(max-width:1023.98px){.yIhlck5n br{display:none}}.dKtc-dMq{border-bottom:1px solid var(--color-black)}.yepCDHj6{color:var(--color-black);cursor:pointer;font-size:max(1.13rem,min(2vw,1.5rem));line-height:1.3;list-style:none;padding-bottom:var(--spacing-md);padding-right:var(--spacing-lg);position:relative}.yepCDHj6:after{content:\"+\";font-size:2.25rem;font-weight:600;line-height:.9;position:absolute;right:0;top:0}.yepCDHj6:active,.yepCDHj6:focus{outline:none}.yepCDHj6::-webkit-details-marker{display:none}.dKtc-dMq[open] .yepCDHj6:after{color:var(--color-primary);content:\"—\";font-size:1.88rem}.yzu7RoAR{font-size:1.13rem;padding-bottom:var(--spacing-md)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_7AkAketf",
	"content": "dYWilShA",
	"title": "yIhlck5n",
	"question": "dKtc-dMq",
	"questionValue": "yepCDHj6",
	"answer": "yzu7RoAR"
};
module.exports = ___CSS_LOADER_EXPORT___;
