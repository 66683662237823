// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5mszZ1nc{grid-gap:6rem;display:grid;gap:6rem;grid-template-columns:repeat(2,1fr);padding:6rem 0}@media(max-width:1324.98px){._5mszZ1nc{padding:6rem var(--spacing-lg)}}@media(max-width:1023.98px){._5mszZ1nc{gap:2rem}}@media(max-width:667.98px){._5mszZ1nc{display:flex;flex-direction:column;padding:4rem var(--spacing-md)}}.lb1WmVHo{color:var(--color-primary);font-size:max(2.19rem,min(4vw,3.75rem));line-height:1.1;margin-bottom:var(--spacing-md);text-transform:uppercase}@media(max-width:667.98px){.lb1WmVHo br{display:none}}._5WU6U6U4{color:var(--color-black);font-size:1.13rem;line-height:1.3}._8oh3sQXD{border:none;border-bottom:1px solid var(--color-black);font-size:max(1.13rem,min(2vw,1.5rem));padding-bottom:var(--spacing-sm);width:100%}._8oh3sQXD::-moz-placeholder{opacity:.3}._8oh3sQXD::placeholder{opacity:.3}.h8UoMTXI{display:flex;gap:var(--spacing-sm);margin-top:var(--spacing-lg)}@media(max-width:947.98px){.h8UoMTXI{flex-direction:column}}.IwOkFLDD .C-z8Wt\\+k{flex-grow:1}.rO-V2-Uc{background-color:var(--color-gray-2);border-radius:2rem;display:flex;flex-direction:column;gap:var(--spacing-sm);padding:2rem}.j5TEw2UG{color:var(--color-black);font-size:.88rem}.WY3j1OCL{color:var(--color-primary);display:block;font-size:2rem;font-weight:600;line-height:1;text-transform:uppercase}.JazBgC-\\+{font-size:1.25rem}.YH\\+flvP8{color:var(--color-error);font-size:.63rem;font-weight:500;line-height:1;max-width:100%;overflow:hidden;padding-top:.19rem;text-overflow:ellipsis;white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_5mszZ1nc",
	"title": "lb1WmVHo",
	"description": "_5WU6U6U4",
	"formInput": "_8oh3sQXD",
	"formButtons": "h8UoMTXI",
	"form": "IwOkFLDD",
	"btn": "C-z8Wt+k",
	"step": "rO-V2-Uc",
	"stepTitle": "j5TEw2UG",
	"stepValue": "WY3j1OCL",
	"stepNotFound": "JazBgC-+",
	"error": "YH+flvP8"
};
module.exports = ___CSS_LOADER_EXPORT___;
