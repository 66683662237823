
import Vue from "vue"

export default Vue.extend({
  data: () => ({
    email: null as String | null,
    loading: false,
    error: null as String | null,
    step: null as String | null,
    states: {
      first: "ваша заявка зарегистрирована",
      second: "ваша заявка одобрена",
      third: "3 этап",
      fourth: "4 этап",
      withdrawal: "отклонена",
      notfound: "конкурсная работа с такими данными не найдена",
    },
  }),

  watch: {
    email () { this.error = null },
  },

  methods: {
    async checkEmail () {
      if (!this.email) return
      this.loading = true
      const [res, err] = await this.$api.user.getProjectState(this.email)
      if (err) this.error = err.message ?? "Ошибка запроса"
      if (!res) this.step = "notfound"
      else this.step = res
      this.loading = false
    },
  },
})
