// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width:1324.98px){.Ee-ak6iK{padding:0 var(--spacing-lg)}}@media(max-width:667.98px){.Ee-ak6iK{padding:0 var(--spacing-md)}}.QQBgAy6Y{grid-gap:10vw;display:grid;gap:10vw;grid-template-columns:3fr 2fr}@media(max-width:1324.98px){.QQBgAy6Y{gap:2.5rem}}@media(max-width:947.98px){.QQBgAy6Y{grid-template-columns:1fr}}.uWuloyRL{font-size:max(1.13rem,min(2vw,1.5rem))}.Io\\+UWAVR{color:var(--color-primary)}.uWuloyRL li::marker{color:var(--color-primary);font-size:1.88rem}._14Se9VfM{display:flex;flex-direction:column;gap:1rem;margin-bottom:2rem;padding-left:2rem}.rEE0\\+20V{font-size:1.13rem;line-height:1.3}.tAb\\+dExl{color:var(--color-primary);font-size:max(2.19rem,min(4vw,3.75rem));line-height:1.1;margin-bottom:var(--spacing-md);text-transform:uppercase}@media(max-width:947.98px){.tAb\\+dExl br{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "Ee-ak6iK",
	"content": "QQBgAy6Y",
	"dates": "uWuloyRL",
	"datesValue": "Io+UWAVR",
	"list": "_14Se9VfM",
	"descriptionText": "rEE0+20V",
	"title": "tAb+dExl"
};
module.exports = ___CSS_LOADER_EXPORT___;
