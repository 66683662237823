import { render, staticRenderFns } from "./index.vue?vue&type=template&id=583cd5a6&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=583cd5a6&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderSponsors: require('/app/components/app/header/AppHeaderSponsors.vue').default,IndexTitle: require('/app/components/index/IndexTitle.vue').default,IndexTargets: require('/app/components/index/IndexTargets.vue').default,IndexParticipants: require('/app/components/index/IndexParticipants.vue').default,IndexDates: require('/app/components/index/IndexDates.vue').default,IndexInstruction: require('/app/components/index/IndexInstruction.vue').default,IndexApplication: require('/app/components/index/IndexApplication.vue').default,IndexWinners: require('/app/components/index/IndexWinners.vue').default,IndexQuestions: require('/app/components/index/IndexQuestions.vue').default})
