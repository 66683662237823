
import Vue from "vue"

type Variant =
  | "primary"
  | "secondary"
  | "outline"
  | "back"
  | "accept"

export default Vue.extend({
  props: {
    text: { type: String, default: "" },
    to: { type: String, default: "" },
    type: { type: String, default: null },
    href: { type: String, default: "" },
    variant: { type: String as VuePropType<Variant>, default: "primary" },
    icon: { type: String, default: "" },
    smallOnMobile: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    is (): string {
      if (this.to) return "nuxt-link"
      if (this.href) return "a"
      return "button"
    },

    getType (): string | undefined {
      if (this.type) return this.type
      return (this.to || this.href) ? undefined : "button"
    },
  },
})
