// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UIw2GUe8{align-items:center;display:flex;justify-content:space-between}@media(max-width:1324.98px){.UIw2GUe8{padding:0 var(--spacing-lg)}}@media(max-width:667.98px){.UIw2GUe8{align-items:flex-start;flex-direction:column;padding:0 var(--spacing-md)}}.d9btaMNi{display:flex;flex-direction:column;gap:2.5rem;max-width:44rem}@media(max-width:667.98px){.d9btaMNi{gap:1.5rem}.d9btaMNi br{display:none}}._2HfbQeGy{color:var(--color-primary);font-size:max(2.5rem,min(5vw,4.38rem));line-height:1.1;text-transform:uppercase}.j9sflv2w{font-size:2rem;transition:color .3s}.j9sflv2w:hover{color:var(--color-primary)}.CwCkgasQ{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "UIw2GUe8",
	"info": "d9btaMNi",
	"title": "_2HfbQeGy",
	"end": "j9sflv2w",
	"image": "CwCkgasQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
